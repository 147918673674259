import React, { useState, useEffect } from "react";
import {
  Text,
  Input,
  Grid,
  Col,
  Button,
  Dialog,
  Group,
  Badge,
  Overlay,
  Modal,
  Divider,
} from "@mantine/core";
import {
  getInvoice,
  getReportFilters,
  triggerInvoiceSend,
  triggerInvoiceVoid,
  updateInvoice,
} from "../../functions/api";
import { DateInput, DatePickerInput } from "@mantine/dates";
import { PDFdoc, returnPDFdoc } from "./PDFscreen";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { Link } from "react-router-dom";
import { ExternalLink } from "tabler-icons-react";
import { getGroup } from "../../functions/tokens";
import hasAccess from "../../functions/access";
import { Comment } from "../../Components";
import { InvoicesReport } from "../Admin/AdminReports";
import { FormattedMessage, useIntl } from "react-intl";

const InvoicesPage = () => {
  const [filters, setFilters] = useState();
  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);
  return <InvoicesReport filters={filters} />;
};

export const ModifyInvoiceModal = ({
  isModalOpen,
  selectedInvoice,
  callBack,
}) => {
  const intl = useIntl();
  const [newPaidAmount, setNewPaidAmount] = useState("");
  const [extended, setExtended] = useState(null);
  const [status, setStatus] = useState();
  const [tmpSts, setTmpsts] = useState("");
  const [paidDate, setPaidDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  let group = getGroup();
  group = group == null ? 10 : group;
  const callSend = async () => {
    if (window.confirm(intl.formatMessage({ id: "confirm_send" }))) {
      setStatus("wait");
      getInvoice({ invoice_id: selectedInvoice.invoice_id }).then(
        async (dx) => {
          try {
            const pdfBlob = await pdf(returnPDFdoc(dx)).toBlob();
            const ret = await triggerInvoiceSend(
              pdfBlob,
              selectedInvoice.invoice_id
            );

            if ((await ret.message) == "WIN") {
              setStatus("sent");
              setTmpsts("send-win");
            }
          } catch (e) {
            // console.log(e);
          }
        }
      );
    }
  };

  const callPaid = () => {
    if (hasAccess(group, "markInvoices", "edit")) {
      if (
        window.confirm(
          intl.formatMessage({ id: "confirm_mark_paid" }) +
            selectedInvoice.total_vat +
            "?"
        )
      ) {
        handlePaidAmountSave(selectedInvoice.invoice_id, "paid");

        setOpen(false);
        callBack(false);
        // triggerInvoicePaid(selectedInvoice.invoice_id).then((x) => {
        //   console.log(x);
        //   setStatus("paid");
        //   setOpen(false);
        //   callBack(false);
        // });
      }
    }
  };

  const callVoid = () => {
    if (window.confirm(intl.formatMessage({ id: "confirm_void" }))) {
      triggerInvoiceVoid(selectedInvoice.invoice_id);
      setStatus("void");
    }
  };

  const handlePaidAmountSave = (id, newStatus) => {
    updateInvoice({
      extended_due: extended,
      paid_amount: newPaidAmount,
      paid_date: paidDate,
      id: id,
      status: newStatus ? newStatus : status,
    });
    callBack(false);
  };

  useEffect(() => {
    if (selectedInvoice.paid_amount > 0) {
      setNewPaidAmount(selectedInvoice.paid_amount);
    }
    if (selectedInvoice.extended_due != null) {
      setExtended(new Date(selectedInvoice.extended_due));
    }
    setStatus(selectedInvoice.status);
  }, []);

  const handleModalClose = () => {
    callBack(false);
    //setNewPaidAmount("");
  };

  const handlePaidAmountChange = (event) => {
    setNewPaidAmount(event.target.value);
  };

  return (
    <div>
      <Dialog
        opened={open}
        style={{ margin: 30 }}
        withCloseButton
        onClose={() => setOpen(false)}
        size="lg"
        radius="md"
        position={{ top: 300 }}
      >
        <Text size="sm" mb="xs" weight={500}>
          {intl.formatMessage({ id: "enter_payment_date" })}
        </Text>

        <Group align="flex-end">
          <DatePickerInput
            style={{ flex: 1 }}
            placeholder={intl.formatMessage({ id: "select_date" })}
            value={paidDate}
            onChange={setPaidDate}
            mx="auto"
          />
          <Button style={{ backgroundColor: "dodgerblue" }} onClick={callPaid}>
            <FormattedMessage id="confirm" />
          </Button>
        </Group>
      </Dialog>

      <Modal
        size="calc(100vw - 3rem)"
        opened={isModalOpen}
        onClose={handleModalClose}
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <>
              <FormattedMessage id="edit_invoice" />
              {selectedInvoice.invoice_name}
            </>{" "}
            {selectedInvoice.status == "void" &&
              hasAccess(group, "markInvoices", "delete") && (
                <Badge color={"red"} variant="filled">
                  <FormattedMessage id="void" />
                </Badge>
              )}
          </div>
        }
      >
        {selectedInvoice.made_for && (
          <Link
            to={{
              pathname: `/partner/${selectedInvoice.made_for}`,
              query: { foo: "bar" },
            }}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "dodgerblue" }}
          >
            <ExternalLink
              size={16}
              style={{
                display: "inline-block",
                paddingBottom: 1,
              }}
            />
            <Text
              style={{
                display: "inline-block",
                fontSize: 14,
                textDecorationLine: "underline",
              }}
            >
              {" "}
              {intl.formatMessage({ id: "view_client_new_tab" })}
            </Text>
          </Link>
        )}
        {/* <ModalBody> */}
        <Grid gutter="lg">
          <Col span={6}>
            <Text fontWeight="bold">
              {intl.formatMessage({ id: "issued_to" })}
            </Text>
            <Input
              value={
                (selectedInvoice?.c_name
                  ? selectedInvoice?.c_name
                  : selectedInvoice?.client?.c_name) +
                " " +
                (selectedInvoice?.c_surname
                  ? selectedInvoice?.c_surname
                  : selectedInvoice?.client?.c_surname)
              }
              disabled
              variant="default"
              size="sm"
            />
          </Col>
          <Col span={6}>
            <Text fontWeight="bold">
              {intl.formatMessage({ id: "invoiced_for" })}
            </Text>
            <Input
              value={
                selectedInvoice?.invoiced_for
                  ? selectedInvoice?.invoiced_for
                  : selectedInvoice?.credentials?.company_name
              }
              disabled
              variant="default"
              size="sm"
            />
          </Col>
          <Col span={6}>
            <Text fontWeight="bold">
              {intl.formatMessage({ id: "issued_by" })}
            </Text>
            <Input
              value={
                (selectedInvoice?.created_by_name
                  ? selectedInvoice?.created_by_name
                  : selectedInvoice?.user) +
                " " +
                (selectedInvoice?.created_by_surname
                  ? selectedInvoice.created_by_surname
                  : "")
              }
              disabled
              variant="default"
              size="sm"
            />
          </Col>
          <Col span={6}>
            <Text fontWeight="bold">
              {intl.formatMessage({ id: "total_vat" })}
            </Text>
            <Input
              value={selectedInvoice.total_vat}
              disabled
              variant="default"
              size="sm"
            />
          </Col>
          <Col span={6}>
            <Text fontWeight="bold">
              {intl.formatMessage({ id: "paid_amount" })}
            </Text>
            <Input
              value={newPaidAmount}
              onChange={handlePaidAmountChange}
              variant="default"
              size="sm"
              disabled={
                selectedInvoice.paid_date
                  ? true
                  : hasAccess(group, "markInvoices", "edit")
                  ? false
                  : true
              }
            />
          </Col>
          <Col span={6}>
            <Text fontWeight="bold">
              {intl.formatMessage({ id: "deadline_extended_to" })}
            </Text>
            <DateInput
              minDate={new Date()}
              value={extended}
              onChange={setExtended}
              disabled={selectedInvoice.paid_date ? true : false}
              disabledx={selectedInvoice.paid_date ? true : false}
            />
          </Col>
          <Col span={12}>
            {status && status != "paid" ? (
              <>
                {" "}
                <FormattedMessage id="actions_title" />
                <div style={{ paddingTop: 15 }}>
                  {status != "void" && (
                    <Button
                      onClick={() => callVoid(selectedInvoice)}
                      style={{ backgroundColor: "red", marginRight: 10 }}
                    >
                      <FormattedMessage id="void" />
                    </Button>
                  )}
                  {status &&
                    status == "draft" &&
                    (selectedInvoice?.client?.c_email ||
                    selectedInvoice?.c_email ? (
                      <Button
                        style={{ backgroundColor: "dodgerblue" }}
                        onClick={() => callSend(selectedInvoice)}
                        mr={3}
                      >
                        <FormattedMessage id="send_to_client" />
                      </Button>
                    ) : (
                      <p style={{ fontWeight: "bold" }}>
                        <FormattedMessage id="request_email_for_send" />
                      </p>
                    ))}
                  {status && tmpSts == "" && status == "sent" && (
                    <Button
                      style={{ backgroundColor: "dodgerblue" }}
                      onClick={() => setOpen(true)}
                      mr={3}
                    >
                      <FormattedMessage id="mark_paid" />
                    </Button>
                  )}
                  {status && tmpSts == "send-win" && (
                    <div
                      style={{
                        backgroundColor: "lightgrey",
                        padding: 10,
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      <FormattedMessage id="successfully_sent" />
                    </div>
                  )}
                  {status && status == "wait" && (
                    <div
                      style={{
                        backgroundColor: "lightgrey",
                        padding: 10,
                        color: "black",
                      }}
                    >
                      {" "}
                      <FormattedMessage id="sending" />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <FormattedMessage id="invoice_paid_closed_at" />{" "}
                {selectedInvoice.paid_date &&
                  new Date(selectedInvoice.paid_date).toLocaleString("en-US", {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
              </>
            )}
          </Col>
        </Grid>

        <div style={{ marginBottom: 15 }} />
        <Divider />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            marginTop: 15,
            justifyContent: "flex-end",
            alignContent: "end",
            alignItems: "end",
          }}
        >
          {/* <Anchor
            href={"/invoices/" + selectedInvoice.invoice_id}
            target="_blank"
            style={{ marginRight: 15 }}
          >
            <FormattedMessage id="add_comment" />
          </Anchor> */}

          <PDFDownloadLink
            document={<PDFdoc id={selectedInvoice.invoice_id} />}
            style={{
              cursor: "pointer",
              color: "orange",
              display: "inline",
            }}
            fileName={"INV" + selectedInvoice.invoice_name + "invoice.pdf"}
          >
            {({ blob, url, loading, error }) =>
              !loading && (
                <Button style={{ backgroundColor: "green" }} mr={3}>
                  <FormattedMessage id="view" /> PDF
                </Button>
              )
            }
          </PDFDownloadLink>

          {!selectedInvoice.paid_date && (
            <Button
              style={{ backgroundColor: "dodgerblue" }}
              onClick={() => handlePaidAmountSave(selectedInvoice.invoice_id)}
              mr={3}
            >
              <FormattedMessage id="save" />
            </Button>
          )}

          <Button onClick={handleModalClose} variant="ghost">
            <FormattedMessage id="cancel" />
          </Button>
        </div>
        {/* </ModalFooter> */}
        <div style={{ marginTop: 20 }}>
          {selectedInvoice &&
            selectedInvoice.comments &&
            selectedInvoice.comments[0].comment != null &&
            selectedInvoice.comments.map((x) => (
              <Comment
                postedAt={x?.created}
                body={x?.comment}
                author={x?.name + " " + x?.surname}
              />
            ))}
        </div>
        {open && <Overlay color="#000" opacity={0.35} />}
      </Modal>
    </div>
  );
};

export const InvoiceListItem = () => {};

export default InvoicesPage;
