import "./App.css";
import { useNavigate } from "react-router-dom";
import { MainContainer } from "./Components";
import { useState, useEffect } from "react";
import { getLanguage, getToken, getUserId } from "./functions/tokens";
import { IntlProvider } from "react-intl";
import { messages } from "./translations";
import { WebPhoneProvider } from "./functions/WebPhoneContext";
import AppRoutes from "./Routes/AppRoutes";
import initGoogleMapsApi from "./functions/initGoogleMapsApi";
import { SettingsProvider } from "./Hooks/SettingsContext";

function App() {
  const [isLoggedIn, setLoggedin] = useState(getToken());
  const navigation = useNavigate();
  const [showbar, setShowbar] = useState(true);
  const [margins, setMargins] = useState(true);

  const [locale, setLocale] = useState(
    getLanguage() || navigator.language || "en-GB"
  );

  useEffect(() => {
    setLoggedin(getToken());
  }, [navigation]);

  useEffect(() => {
    initGoogleMapsApi(isLoggedIn);
  }, [isLoggedIn]);

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
      defaultLocale={"en-GB"}
    >
      <SettingsProvider use={isLoggedIn}>
        <WebPhoneProvider
          useProvider={isLoggedIn}
          key={isLoggedIn && getUserId()}
        >
          <MainContainer
            show={isLoggedIn}
            locale={locale}
            showTop={showbar}
            marginOffset={margins}
          >
            <AppRoutes
              setMargins={setMargins}
              isLoggedIn={isLoggedIn}
              setLoggedin={setLoggedin}
              shwBr={setShowbar}
            />
          </MainContainer>
        </WebPhoneProvider>
      </SettingsProvider>
    </IntlProvider>
  );
}

export default App;
