import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
  Font,
  Link,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { getInvoice } from "../../functions/api";
import { env } from "../../env";
import { useSettings } from "../../Hooks/SettingsContext";

const logo = require(`../../assets/${env.LOGO ? env.LOGO : "m_logo.png"}`);

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    backgroundColor: "white",
  },
  section: {
    flexGrow: 1,
  },
  title: {
    fontSize: 16,
    fontFamily: "Roboto",
    fontStyle: "bold",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  borderBottom: {
    borderBottom: "2px solid black",
    height: 140,
  },
  boldText: {
    paddingTop: 10,
    fontWeight: "bold",
    paddingBottom: 5,
    fontStyle: "bold",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
  },
  labelText: {
    width: 550,
  },
  contentText: {
    wordWrap: "break-word",
    width: 700,
    paddingBottom: 5,
    textAlign: "left",
  },
  bold: {
    fontStyle: "bold",
  },
  mainTitle: {
    borderTop: "2px solid black",
    borderBottom: "2px solid black",
    display: "flex",
    flexDirection: "row",
  },
  smallRow: {
    display: "flex",
    flexDirection: "row",
  },
  mainRow: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 2,
    paddingBottom: 2,
    borderBottom: "1px solid black",
  },
  w20b: {
    width: 20,
    fontStyle: "bold",
  },
  w300: {
    width: 300,
  },
  w50: {
    width: 50,
  },
  w100: {
    width: 100,
  },
  w100b: {
    width: 100,
    fontStyle: "bold",
  },
  w420: {
    width: 420,
  },
  notes: {
    fontStyle: "bold",
    marginTop: 10,
    marginBottom: 20,
  },
  boldNote: {
    fontStyle: "bold",
    marginTop: 10,
  },
});

export const PDFdoc = ({ id, modified }) => {
  const [data, setData] = useState();

  useEffect(() => {
    getInvoice({ invoice_id: id }).then((dx) => {
      setData(dx);
    });
  }, []);

  return <>{data && pdfInside(data, modified)}</>;
};

const DataRow = ({ data }) => {
  return (
    <View>
      <View style={{ padding: 5, fontSize: 8 }}>
        <View style={styles.row}>
          <Text style={styles.labelText}>Nosaukums:</Text>
          <Text style={{ ...styles.contentText, ...styles.bold }}>
            {data && data.company_name
              ? data.company_name
              : (data.c_name && data.c_name) +
                " " +
                (data.c_surname && data.c_surname)}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.labelText}>Juridiskā Adrese:</Text>
          <Text style={styles.contentText}>
            {data && data.jur_address
              ? data.jur_address
              : data && data.c_jur_address
              ? data.c_jur_address
              : null}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.labelText}>Faktiskā adrese:</Text>
          <Text style={styles.contentText}>
            {data && data.fact_address
              ? data.fact_address
              : data && data.c_act_address
              ? data.c_act_address
              : null}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.labelText}>Vienotais reģistrācijas Nr.:</Text>
          <Text style={styles.contentText}>
            {data && data.reg_nr
              ? data && data.reg_nr
              : data && data.c_bussinessid
              ? data.c_bussinessid
              : null}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.labelText}>PVN maksātāja Nr.:</Text>
          <Text style={styles.contentText}>
            {data && data.vat
              ? data.vat
              : data && data.c_vat
              ? data.c_vat
              : null}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.labelText}>Banka:</Text>
          <Text style={styles.contentText}>
            {data && data.bank_name
              ? data.bank_name
              : data && data.c_bank
              ? data.c_bank
              : null}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.labelText}>Konts:</Text>
          <Text style={styles.contentText}>
            {data && data.bank_account
              ? data.bank_account
              : data && data.c_bank_account
              ? data.c_bank_account
              : null}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.labelText}>Kods:</Text>
          <Text style={styles.contentText}>
            {data && data.swift
              ? data.swift
              : data && data.c_swift
              ? data.c_swift
              : null}
          </Text>
        </View>
      </View>
    </View>
  );
};

const PDFscreen = (data) => {
  return (
    <div>
      <PDFDownloadLink document={<PDFdoc id={24} />} fileName="somename.pdf">
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink>
      <hr style={{ padding: 20 }}></hr>
      <PDFdoc id={24} />
    </div>
  );
};

const pdfInside = (data, modified) => (
  <Document style={{ fontFamily: "Roboto", fontSize: 9 }}>
    <Page size="A4">
      <View size="A4" style={styles.container}>
        <View
          style={{
            ...styles.section,
            ...{
              paddingLeft: 10,
              marginLeft: 10,
              marginRight: 5,
            },
          }}
        >
          <View style={styles.borderBottom}>
            <View style={{ marginTop: "auto" }}>
              <Image style={{ width: 175, marginBottom: 20 }} src={logo} />
              <Text style={styles.title}>
                {env.INVNAME} Nr. {data.invoice_name}
              </Text>
              <Text style={styles.boldText}>Piegādātājs</Text>
            </View>
          </View>
          <DataRow data={data.credentials} />
        </View>
        <View
          style={{
            ...styles.section,
            ...{
              paddingRight: 10,
              marginLeft: 5,
              marginRight: 10,
            },
          }}
        >
          <View style={styles.borderBottom}>
            <View style={{ marginTop: "auto", fontStyle: "bold" }}>
              <Text>
                Datums:{" "}
                {data.created_on &&
                  new Date(data.created_on).toLocaleDateString()}
              </Text>
              <Text>
                Apmaksāt līdz: {new Date(data.due_date).toLocaleDateString()}
              </Text>
              {/* <Text>
                Apmaksāt līdz:{" "}
                {data.extended_due
                  ? new Date(data.extended_due).toLocaleDateString()
                  : new Date(data.due_date).toLocaleDateString()}
              </Text> */}
              <Text style={styles.boldText}>Pasūtītājs</Text>
            </View>
          </View>
          <DataRow data={data.client} />
        </View>
      </View>

      <View
        style={{
          margin: 10,
          marginLeft: 10,
          paddingLeft: 5,
          marginRight: 50,
        }}
      >
        <View style={styles.mainTitle}>
          <Text style={styles.w20b}>Nr.</Text>{" "}
          <Text style={{ ...styles.w300, ...styles.bold }}>Paskaidrojums</Text>
          <Text style={{ ...styles.w50, ...styles.bold }}>Mērv.</Text>
          <Text style={{ ...styles.w50, ...styles.bold }}>Skaits</Text>
          <Text style={{ ...styles.w100, ...styles.bold }}>Cena</Text>
          <Text style={{ ...styles.w100, ...styles.bold }}>Summa (EUR)</Text>
          <Text style={{ ...styles.w50, ...styles.bold }}>PVN</Text>
        </View>
        {data &&
          data.services_json &&
          JSON.parse(data.services_json).map((service, index) => (
            <View style={styles.mainRow}>
              <Text style={styles.w20b}>{index + 1}.</Text>{" "}
              <Text style={styles.w300}>{service.name}</Text>
              <Text style={styles.w50}>gab</Text>
              <Text style={styles.w50}>
                {service.amount ? service.amount : 1}
              </Text>
              <Text style={styles.w100}>
                {service.cost}
                {/* {(
                  service.cost -
                  (service.cost * (1 + Number(data.vat_perc)) - service.cost)
                ).toFixed(2)} */}
              </Text>
              <Text style={styles.w100}>
                {service.cost * (service.amount ? service.amount : 1)}
              </Text>
              <Text style={styles.w50}>{data.vat_perc * 100}%</Text>
            </View>
          ))}
        <View style={styles.smallRow}>
          <Text style={styles.w420}></Text>{" "}
          <Text style={styles.w100}>Kopā</Text>
          <Text style={styles.w100}>{data.total_novat} (EUR)</Text>
          <Text style={styles.w50}></Text>
        </View>
        {data && data.vat_perc > 0 && (
          <View style={styles.smallRow}>
            <Text style={styles.w420}></Text>{" "}
            <Text style={styles.w100}>PNV Summa</Text>
            <Text style={styles.w100}>{data.vat_value} (EUR)</Text>
            <Text style={styles.w50}></Text>
          </View>
        )}
        <View style={styles.smallRow}>
          <Text style={styles.w420}></Text>{" "}
          <Text style={styles.w100}>Kopsumma</Text>
          <Text style={styles.w100}>{data.total_vat} (EUR)</Text>
          <Text style={styles.w50}></Text>
        </View>
        {data && modified && data.paid_amount && (
          <View style={styles.smallRow}>
            <Text style={styles.w420}></Text>{" "}
            <Text style={styles.w100}>Jau samaksāts</Text>
            <Text style={styles.w100}>{data.paid_amount} (EUR)</Text>
            <Text style={styles.w50}></Text>
          </View>
        )}
        {data && data.discount > 0 && (
          <View style={styles.smallRow}>
            <Text style={styles.w420}></Text>{" "}
            <Text style={styles.w100}>Atlaide</Text>
            <Text style={styles.w100}>
              {data.discount}% (
              {(data.total_vat * (data.discount / 100)).toFixed(2)} EUR)
            </Text>
            <Text style={styles.w50}></Text>
          </View>
        )}
        <View style={styles.smallRow}>
          <Text style={styles.w420}></Text>{" "}
          <Text style={styles.w100b}>Summa apmaksai</Text>
          <Text style={styles.w100b}>
            {(
              (data.discount
                ? (data.total_vat * (1 - data.discount / 100)).toFixed(2)
                : data.total_vat) - (modified ? data.paid_amount : 0)
            ).toFixed(2)}{" "}
            (EUR)
          </Text>
          <Text style={styles.w50}></Text>
        </View>
      </View>

      <View style={{ margin: 20 }}>
        <Text style={{ fontStyle: "bold", marginTop: 10 }}>
          Apmaksas veids: Ar pārskaitījumu
        </Text>
        <Text style={{ fontStyle: "bold", marginTop: 10, marginBottom: 20 }}>
          Piezīmes
        </Text>
        <Text style={{ fontStyle: "bold" }}>
          Rēķins sagatavots elektroniski un ir derīgs bez paraksta
        </Text>
        {env.EPAY && data && data.encrypted && (
          <Link
            style={{ textDecoration: "none", cursor: "pointer" }}
            src={
              "https://jbmnj.lv/epay.php?e=" +
              encodeURIComponent(data.encrypted)
            }
          >
            <View
              style={{
                backgroundColor: "blue",
                padding: 10,
                borderRadius: 5,
                margin: "20px auto",
                width: 300,
                textAlign: "center",
              }}
            >
              <Text
                style={{ color: "white", fontWeight: "bold", fontSize: 30 }}
              >
                Maksāt šeit!
              </Text>
            </View>
          </Link>
        )}
      </View>
    </Page>
  </Document>
);

export const returnPDFdoc = (data) => pdfInside(data);

export default PDFscreen;
